import React, { useCallback } from 'react';
import Calendar from 'react-calendar';
// import "react-calendar/dist/Calendar.css";
import { Value } from './types';
import { BookedEvent, CalendarConfig } from '../../services/rest';
import './Calendar.css';

interface CalendarProps {
  selectedDate: Value;
  selectedTime: string | null;
  bookedEvents: BookedEvent[];
  calendarConfig: CalendarConfig;
  isLoading: boolean;
  isProcessing: boolean;
  onDateChange: (date: Value) => void;
  onTimeSelect: (timeSlot: string) => void;
  onNext: () => void;
}

export const BookingCalendar: React.FC<CalendarProps> = ({
  selectedDate,
  selectedTime,
  bookedEvents,
  calendarConfig,
  isLoading,
  isProcessing,
  onDateChange,
  onTimeSelect,
  onNext
}) => {
  // Can only book tomorrow
  const tomorrow = new Date();
  tomorrow.setDate(tomorrow.getDate() + 1);
  tomorrow.setHours(0, 0, 0, 0);
  // Helper function to check if a time slot overlaps with any booked event
  const isTimeSlotBooked = useCallback((date: Date, startTime: string, endTime: string): boolean => {
    if (!bookedEvents.length) return false;

    const dateStr = date.toISOString().split('T')[0];
    const [startHour, startMinute] = startTime.split(':');
    const [endHour, endMinute] = endTime.split(':');

    const slotStart = new Date(date);
    slotStart.setHours(parseInt(startHour), parseInt(startMinute), 0, 0);

    const slotEnd = new Date(date);
    slotEnd.setHours(parseInt(endHour), parseInt(endMinute), 0, 0);

    return bookedEvents.some(event => {
      const eventStart = new Date(event.start.dateTime);
      const eventEnd = new Date(event.end.dateTime);

      // Check if there's any overlap
      return (
        (slotStart >= eventStart && slotStart < eventEnd) ||
        (slotEnd > eventStart && slotEnd <= eventEnd) ||
        (slotStart <= eventStart && slotEnd >= eventEnd)
      );
    });
  }, [bookedEvents]);

  // Update tileDisabled to include new date restrictions
  const tileDisabled = useCallback(({ date, view }: { date: Date, view: string }) => {
    if (view !== 'month') {
      return false;
    }

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Calculate the max date (12 months from today)
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() + 1);
    maxDate.setMonth(maxDate.getMonth() + 1, 1); // Set to first day of next month
    maxDate.setDate(0); // This will give us the last day of the previous month
    maxDate.setHours(23, 59, 59, 999); // Set to end of day

    // Check if it's a past date or beyond 12 months
    if (date < today || date >= maxDate) {
      return true;
    }

    // Check if the day is available in config
    const day = date.getDay().toString();
    if (!calendarConfig?.availableDays[day]) {
      return true;
    }

    // Check if all time slots for this day are booked
    const availableSlots = calendarConfig.availableDays[day];
    const allSlotsBooked = availableSlots.every(slot =>
      isTimeSlotBooked(date, slot.startTime, slot.endTime)
    );

    return allSlotsBooked;
  }, [calendarConfig, isTimeSlotBooked]);

  // Update tileClassName to show different styles for booked dates
  const tileClassName = useCallback(({ date, view }: { date: Date, view: string }) => {
    if (view !== 'month') return '';

    const today = new Date();
    today.setHours(0, 0, 0, 0);

    // Calculate the max date (12 months from today)
    const maxDate = new Date();
    maxDate.setFullYear(today.getFullYear() + 1);
    maxDate.setMonth(maxDate.getMonth() + 1, 1); // Set to first day of next month
    maxDate.setDate(0); // This will give us the last day of the previous month
    maxDate.setHours(23, 59, 59, 999); // Set to end of day

    if (date < today || date >= maxDate) {
      return "unavailable-date";
    }

    const day = date.getDay().toString();

    if (!calendarConfig?.availableDays[day]) {
      return "unavailable-date";
    }

    const availableSlots = calendarConfig.availableDays[day];
    const allSlotsBooked = availableSlots.every(slot =>
      isTimeSlotBooked(date, slot.startTime, slot.endTime)
    );

    if (allSlotsBooked) {
      return "booked-date";
    }

    return "available-date";
  }, [calendarConfig, isTimeSlotBooked]);

  // Get available time slots for a selected date
  const getAvailableTimeSlots = useCallback((date: Date) => {
    if (!calendarConfig) return [];

    const day = date.getDay().toString();
    const availableRanges = calendarConfig.availableDays[day] || [];

    return availableRanges.map(range => {
      const isBooked = isTimeSlotBooked(date, range.startTime, range.endTime);

      // Format the time slot display
      const formatTime = (time: string) => {
        const [hours, minutes] = time.split(':');
        const period = parseInt(hours) >= 12 ? 'PM' : 'AM';
        const hour = parseInt(hours) % 12 || 12;
        return `${hour}:${minutes} ${period}`;
      };

      const displayTime = `${formatTime(range.startTime)} - ${formatTime(range.endTime)}`;

      return {
        time: displayTime,
        available: !isBooked
      };
    });
  }, [calendarConfig, isTimeSlotBooked]);

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center p-4">
        <div className="spinner-border text-primary" role="status">
          <span className="visually-hidden">Loading...</span>
        </div>
      </div>
    );
  }

  return (
    <div className="container">
      <div className="row">
        <div className="col-md-7 mb-4 mb-md-0">
          <h3 className="mb-4">Select a Date & Time</h3>
          <Calendar
            onChange={onDateChange}
            value={selectedDate}
            tileDisabled={tileDisabled}
            tileClassName={tileClassName}
            minDetail="month"
            maxDetail="month"
            minDate={tomorrow}
            maxDate={(() => {
              const maxDate = new Date();
              maxDate.setFullYear(maxDate.getFullYear() + 1);
              maxDate.setMonth(maxDate.getMonth() + 1, 1);
              maxDate.setDate(0);
              return maxDate;
            })()}
            navigationLabel={({ date }) =>
              date.toLocaleDateString('en-US', { month: 'long', year: 'numeric' })
            }
            next2Label={null}  // Add this to remove next year button
            prev2Label={null}  // Add this to remove previous year button
            className="custom-calendar"
          />
        </div>

        {selectedDate && (
          <div className="col-md-5">
            <h4 className="mb-3">
              Available Times for {selectedDate.toLocaleString('en-US', {
                // weekday: 'long',
                year: 'numeric',
                month: 'long',
                day: 'numeric'
              })}
            </h4>
            <div className="d-flex flex-wrap gap-2">
              {getAvailableTimeSlots(selectedDate as Date).map(({ time, available }) => (
                <button
                  key={time}
                  className={`btn ${selectedTime === time
                      ? 'btn-primary'
                      : available
                        ? 'btn-outline-primary'
                        : 'btn-outline-secondary'
                    }`}
                  onClick={() => available && onTimeSelect(time)}
                  disabled={!available}
                >
                  {time}
                  {!available && (
                    <span className="ms-2 badge bg-secondary">Booked</span>
                  )}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      <div className="row mt-4">
        <div className="col-12">
          <div className="d-flex justify-content-center">
            <button
              onClick={onNext}
              className="btn btn-primary"
              disabled={!selectedDate || !selectedTime || isProcessing}
            >
              {isProcessing ? (
                <div className="d-flex align-items-center">
                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                  Processing...
                </div>
              ) : (
                'Continue to Details'
              )}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}; 